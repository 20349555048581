<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://infoguia.com/" target="_blank">Infoguia</a>
      <!-- <span class="ml-1">{{new Date().getFullYear()}}</span> -->
      <span class="ml-1">2021</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">Desarrollado por <b>Infoguia</b></span>
      <!-- <a href="https://infoguia.com/" target="_blank">Infoguia</a> -->
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
