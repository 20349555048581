<template>
	<CDropdown
		inNav
		class="c-header-nav-items"
		placement="bottom-end"
		add-menu-classes="pt-0"
	>
		<template #toggler>
			<CHeaderNavLink>
				<div class="c-avatar">
					<!-- <img src="img/avatars/6.jpg" class="c-avatar-img " /> -->
					<img :src="imgAvatar" class="c-avatar-img " />
				</div>
			</CHeaderNavLink>
		</template>
		<CDropdownHeader tag="div" class="text-center" color="light">
			<strong>Datos de la Cuenta</strong>
		</CDropdownHeader>
		<!-- <CDropdownItem>
			<i class="fas fa-user-tie"></i> {{ userName }}
		</CDropdownItem>
		<CDropdownItem>
			<i class="fas fa-at"></i> {{ userEmail }}
		</CDropdownItem> -->
		<CDropdownItem style="cursor:default">
			<i class="fas fa-user-tie edit-blue"> </i>
			<span style="margin-left:10px;margin-top:4px">{{
				userInfo.name
			}}</span>
		</CDropdownItem>
		<CDropdownItem style="cursor:default" v-if="userInfo.email">
			<i class="fas fa-at green-email"> </i>
			<span style="margin-left:10px">{{ userInfo.email }}</span>
		</CDropdownItem>
		<CDropdownDivider />
		<CDropdownItem @click="logOut">
			<i class="fas fa-power-off danger-red"></i>
			<span style="margin-left:10px">Cerrar Sesi&oacute;n</span>
		</CDropdownItem>
	</CDropdown>
</template>

<script>
	export default {
		name: 'TheHeaderDropdownAccnt',
		data() {
			return {
				imgAvatar: process.env.VUE_APP_AVATAR_INFOGUIA,
				//userInfo: JSON.parse(sessionStorage.getItem('userData')),
				userInfo: {},
				// userName: this.$store.state.dataUser.name,
				// userEmail: this.$store.state.dataUser.email,
			};
		},
		created() {
			this.userInfo = JSON.parse(sessionStorage.getItem('userData'));
		},
		methods: {
			logOut() {
				this.$store
					.dispatch('auth/logout')
					.then((response) => {
						this.$router.push({
							name: 'Login',
						});
					})
					.catch((error) => {
						console.log(error.response);
						this.$router.push({
							name: 'Login',
						});
					});
			},
		},
	};
</script>

<style scoped>
	.c-icon {
		margin-right: 0.3rem;
	}
</style>
