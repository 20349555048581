<template>
	<CHeader v-if="widgetProcess" :fixed="false" with-subheader class="headerCustom2">
		<CHeaderBrand to="/">
			<img class="" :src="this.imgLogo" style="width: 70%;" />
		</CHeaderBrand>
	</CHeader>
    <CHeader v-else fixed with-subheader class="headerCustom2">
		<CHeaderBrand to="/">
			<img class="" :src="this.imgLogo" style="width: 70%;" />
		</CHeaderBrand>
		<CHeaderNav class="mr-auto"> </CHeaderNav>
		<CHeaderNav class="">
			<TheHeaderDropdownAccnt />
		</CHeaderNav>
	</CHeader>
</template>

<script>
	import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt';

	export default {
		name: 'TheHeaderClients',
		components: {
			TheHeaderDropdownAccnt,
		},
		data() {
			return {
				imgLogo: process.env.VUE_APP_LOGO_INFOGUIA,
				widgetProcess: true,
			};
		},
		created() {
			if (sessionStorage.getItem('userType') !== null) {
				this.widgetProcess = false;
			}
		},
	};
</script>
